'use strict'

// postcss stylesheets
import "./../styles/_tailwind.pcss";
import "./../styles/main.pcss";

const scripts = {
  DOMContentLoaded: () => import('./_DOMContentLoaded'),
  everythingLoaded: () => import('./_everythingLoaded')
}

document.addEventListener('DOMContentLoaded', () => scripts.DOMContentLoaded().then(module => module.DOMContentLoadedInit()))
document.addEventListener('everythingLoaded', () => scripts.everythingLoaded().then(module => module.everythingLoadedInit()))
